<template>
  <div>
    <div class="choose-type-content">
      <div class="mb-4 d-flex justify-content-center btn-choose-customer">
      <Button @submit="button1 = true;changeTypeCustomer(constant.USER_TYPE.REPEATER)" content="空室確認・予約する" customStyle="width:75%" :customClassName="button1 ? 'border-aqua-and-background-white' : 'border-none-and-background-orange'"></Button>
      </div>
      <div class="d-flex justify-content-center btn-choose-customer">
      <Button @submit="button2 = true;changeTypeCustomer(constant.USER_TYPE.NEW_CUSTOMER)" :customClassName="button2 ? 'border-aqua-and-background-white' : 'border-none-and-background-aqua'" content="初めての方はこちら" customStyle="width:75%"></Button>
      </div>
    </div>
    <div class="d-flex justify-content-center btn-combo-choose">
      <div>
<!--        <a class="d-flex align-items-center" href="" @click.prevent="nextStep()" > <i class="el-icon-arrow-right"></i><font>予約のfdfdf流れに進む</font></a>-->
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import Button from "@/components/layout/components/ButtonCommon";
import {
  TYPE_CUSTOMER,
} from "@/core/services/store/booking.module";
import * as constants from '@/core/config/constant';
export default {
  name: "customer-type",
  components:{
    Button
  },
  data() {
    return {
      type: 0,
      constant: constants,
      button1 : false,
      button2 : false
    }
  },
  computed: {
    ...mapGetters(["typeCustomerChoose", "currentUser"]),
  },
  created() {
    if (this.typeCustomerChoose !== constants.USER_TYPE.NEW_CUSTOMER && this.typeCustomerChoose !== constants.USER_TYPE.REPEATER) {
      this.typeCustomer(constants.USER_TYPE.REPEATER);
    }
    this.type = this.typeCustomerChoose
    if(this.currentUser && this.currentUser.email === null) {
      this.$router.push({ name: 'register', query: { type: 'choose-type-user' } })
    }
  },
  methods: {
    ...mapActions([TYPE_CUSTOMER]),

    changeTypeCustomer(type) {
      //type = 0 -> New customer || type = 1 repeater
      this.type = type;
      this.typeCustomer(type);
      this.nextStep();
    },

    nextStep() {
      if(this.type === 1) {
        this.$router.push({
          name: 'calendar-for-repeater'
        })
      }else {
        this.$router.push({
          name: 'combo-list',
          params: {
            'type': this.type
          },
          query: {
            id: this.$route.query.id}
        })
      }

    }
  }

};
</script>

<style scoped>
.choose-type-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 70vh;
}
.btn-combo-choose > div > a {
  color: #191970 !important;
  font-size: 23px;
  font-weight: 600;
}
.btn-combo-choose > div > a > span {
  color: #191970 !important;
  font-size: 40px;
  font-weight: 100;
}
.btn-combo-choose >div > a:hover {
  text-decoration: none;
}
</style>
