<template>
  <div class="list-combo-service">
    <div>
      <div>
      <process :step="0"></process>
      </div>
      <div style="height: 50px"></div>
<!--      <el-form align="center" class="">
        <el-row v-if="listServiceCombo" class="selected-store">
          <el-form-item v-if="listServiceCombo">
            <div class="el-select el-select-combo-list">
              <div class="el-input el-input&#45;&#45;suffix">
                <input
                    class="el-input__inner"
                    readonly
                    :value="listServiceCombo.length > 0 ? listServiceCombo[0].name : ''"
                    placeholder="白井店"
                />
              </div>
            </div>
          </el-form-item>
        </el-row>
      </el-form>-->
      <div class="d-flex category_combo_service">
<!--          <Button :class="tabActive == 2 ? 'active-search' : 'not-active-search'" :content="'コースメニュー' + (countService[2].count>0 ? ` (${countService[2].count})` : '')" @submit="activeTab(2)" customStyle="width:50%; color:white"></Button>-->
          <Button :class="tabActive == 1 ? 'active-search' : 'not-active-search'" :content="'メニュー'" @submit="activeTab(1)" customStyle="width:100%; color:white"></Button>
      </div>
      <div v-if="tabActive == 1">
        <Button :key="0" :class="category == constants.CATEGORY.CATEGORY_1 ? 'category_1' : 'category_default'" @submit="changeCategory(constants.CATEGORY.CATEGORY_1)" :content="'新規の方'" customStyle="background:#435969;width:100%;border: 1px solid white;"></Button>
        <Button :key="1" :class="category == constants.CATEGORY.CATEGORY_2 ? 'category_2' : 'category_default'" @submit="changeCategory(constants.CATEGORY.CATEGORY_2)" :content="'2回目以降の方'" customStyle="background:#ff8c00;width:100%"></Button>
<!--        <Button :key="2" :class="category == constants.CATEGORY.CATEGORY_3 ? 'category_3' : 'category_default'" @submit="changeCategory(constants.CATEGORY.CATEGORY_3)" :content="'マイチケット' + (countService[tabActive][3]>0 ? ` (${countService[tabActive][3]})` : '')" customStyle="background:#97C7CA;width:100%;border: 1px solid white;"></Button>-->
      </div>
      <el-form align="center" class="">
        <el-row v-if="listServiceCombo">
          <el-form-item>
            <el-tabs type="border-card" :class="tabActive == 2 ? 'tab-combo-active' : 'tab-service-active'" class="el-tab-list-service">
              <el-tab-pane label="">
                <template v-for="item in comboList">
                  <el-form-item :key="item.id" :value="item.id">
                    <el-row class="el-row-custom">
                      <el-row class="name-service">{{item.name}}</el-row>
                      <el-col>
                        <div class="">
                          <img class="img-body-common" :src="item.image">
                        </div>
                      </el-col>
                      <el-col class="my-4">
                        <p style="text-align: left">
                          <span class="text-title"> サービス明細: </span> <span class="detail-content">{{ item.content }}</span>
                        </p>
                        <table class="table table-sm service-combo-proceess">
                          <thead>
                          <tr>
                            <th class="text-title" scope="col">料金</th>
                            <th class="text-title" scope="col">施術時間</th>
                          </tr>
                          </thead>
                          <tbody style="border: none">
                          <tr v-for="(item2, index) in item.process"
                              :key="index"
                              :label="item2.price"
                              :value="item2.id">
                            <td class="detail-content">{{formatNumber(item2.price)}}円</td>
                            <td class="detail-content">{{formatNumber(item2.use_time)}}分</td>
                          </tr>
                          </tbody>
                        </table>
                      </el-col>
                        <div class="custom-btn-combo-detail row">
                          <div class="d-flex justify-content-between">
                            <Button @submit="$router.push({name: 'service-combo-detail', params: {id:item.id, name: 'combo-list' }, query: {store: store_id, tabActive: tabActive, category: category}})" content="詳 細" customClassName="border-aqua-and-background-white" customStyle="width:46%;padding:12px 20px;"></Button>
                            <Button @submit="cancelChose(item, 2)" v-if="checkArrId({id: item.id, type: 2})" content="選択済み" customClassName="border-none-and-background-aqua"  customStyle="width:46%;padding:13px 20px;"></Button>
                            <Button @submit="chooseByCustomer(item, 2)" v-else content="選択する" customClassName="border-aqua-and-background-white"  customStyle="width:46%;padding:13px 20px;"></Button>
                          </div>
                        </div>
                    </el-row>
                  </el-form-item>
                </template>
              </el-tab-pane>
              <el-tab-pane label="">
                <el-form-item v-for="item in serviceList" :key="item.id" :value="item.id" prop="parent">
                  <el-row class="el-row-custom">
                    <el-row class="name-service">
                      {{item.name}}
                    </el-row>
                    <el-col>
                      <div class="">
                        <img class="img-body-common" :src="item.image">
                      </div>
                    </el-col>
                    <el-col class="my-4">
                      <p style="text-align: left">
                        <span class="text-title"> サービス明細: </span> <span class="detail-content">{{ item.content }}</span>
                      </p>
                      <table class="table table-sm service-combo-proceess">
                        <thead>
                        <tr>
                          <th class="text-title" scope="col">料金</th>
                          <th class="text-title" scope="col">施術時間</th>
                        </tr>
                        </thead>
                        <tbody style="border: none">
                        <tr v-for="(item2, index) in item.process"
                            :key="index"
                            :label="item2.price"
                            :value="item2.id">
                          <td class="detail-content">{{formatNumber(item2.price)}}円</td>
                          <td class="detail-content">{{formatNumber(item2.use_time)}}分</td>
                        </tr>
                        </tbody>
                      </table>

                    </el-col>
                    <div class="custom-btn-combo-detail row">
                      <div class="d-flex justify-content-between">
                        <Button @submit="$router.push({name: 'service-detail', params: {id:item.id , name: 'combo-list'}, query: {store: store_id, tabActive: tabActive, category: category}})" content="詳 細" customClassName="border-aqua-and-background-white" customStyle="width:46%;padding:12px 20px;"></Button>
                        <Button @submit="cancelChose(item, 1)" v-if="checkArrId({id: item.id, type: 1})" content="選択済み" customClassName="border-none-and-background-aqua"  customStyle="width:46%;padding:13px 20px;"></Button>
                        <Button @submit="chooseByCustomer(item, 1)" v-else content="選択する" customClassName="border-aqua-and-background-white"  customStyle="width:46%;padding:13px 20px;"></Button>
                      </div>
                    </div>
                  </el-row>
                  <br>
                </el-form-item>
              </el-tab-pane>
            </el-tabs>
          </el-form-item>
          <el-form-item class="btn-submit-step">
            <Button @submit="nextStepCheck()" content="ご予約に進む">
              <template><span>ご予約に進む</span><font style="font-size: 36px; position: relative;top: 4px;"> </font>
              </template>
            </Button>
          </el-form-item>
        </el-row>
      </el-form>
    </div>
    <!-- dialog error not choose -->
    <el-dialog :visible.sync="dialogError" width="30%" center>
      <div>
        <div class="modal-body content_popup text-center" >
          <el-form class="custom-col el-form-custom" ref="dialogError">
            サービスをご選択してください。
          </el-form>
        </div>
        <div class="modal-footer text-center" style="justify-content: center">
          <b-button style="width: 120px;" class="btn-back" @click="closeModalCheckChoose()">
            閉じる
          </b-button>
        </div>
      </div>
    </el-dialog>
    <!-- end dialog error not choose -->
    <!-- dialog choose service type -->
    <el-dialog :visible.sync="dialogChooseServiceType" width="500px" center :show-close="true">
      <div>
        <el-row>
          <div class="modal-body content_popup">
            <el-row :gutter="12">
              <el-col class="mt-2" style="cursor: pointer" :xs="24" :sm="12" :md="24" :lg="12" :xl="12" v-for="item in currentChoose.process" :key="item.id">
                <div @click="chooseTypeByCustomer(item, currentChoose.type)">
                  <el-card :shadow="checkItemId(item, currentChoose.type) ? 'always' : 'never'" :class="checkItemId(item, currentChoose.type) ? 'border-none-and-background-aqua-new' : ''">
                    <div class="text-center">
                      <p class="detail-content"><span :class="checkItemId(item, currentChoose.type) ? 'text-title-choose' : 'text-title'">料金:</span> {{formatNumber(item.price)}} 円</p>
                      <p class="detail-content"><span :class="checkItemId(item, currentChoose.type) ? 'text-title-choose' : 'text-title'">施術時間:</span> {{item.use_time}} 分</p>
                    </div>
                  </el-card>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-row>
      </div>
    </el-dialog>
    <!-- end dialog choose service type -->
  </div>
</template>

<script>

import { mapGetters, mapActions } from "vuex";
import {
  GET_SERVICE_LIST,
  SET_CHOOSE_PROCESS,
  TYPE_CUSTOMER,
  SET_ALL_CHOOSE_PROCESS,
  SET_STORE_ID,
  REMOVE_CHOOSE_PROCESS
} from "@/core/services/store/booking.module";
import * as constants from "@/core/config/constant";
import process from "@/components/layout/components/process";
import Button from "@/components/layout/components/ButtonCommon";

export default {
  name: "chooseComboService",
  components : {
    process,
    Button
  },
  data() {
    return {
      form: {
        nameStore: 0,
      },
      checkPopupCancelBooking: false,
      dialogError: false,
      dialogChooseServiceType: false,
      arrayId: [],
      currentChoose: '',
      id: '',
      store_id: 1,
      stores: [],
      comboList: [],
      serviceList: [],
      tabActive : 1,
      constants: constants,
      category: 0,
      currentProcess: ''
    }
  },
  computed: {
    ...mapGetters(["listServiceCombo","dataProcess", "typeCustomerChoose", "isAuthenticated", "currentUser", "storeId"]),
    countService () {
      //service: 1, combo: 2
      let countService = {
        1: {count: 0, 1 : 0, 2 : 0, 3 : 0},
        2: {count: 0, 1 : 0, 2 : 0, 3 : 0},
      }
      this.dataProcess.forEach(process => {
        if (!process.type) {
          this.removeChooseProcess()
          window.location.reload()
        }
        countService[process.type].count ++
        countService[process.type][process.category_id] ++
      })
      return countService
    },
  },
  async created() {
    if (this.$route.params.type === 0) {
      this.changeCategory(constants.CATEGORY.CATEGORY_1);
    }
    if (this.storeId) {
      this.store_id = this.storeId
    }
    if (this.typeCustomerChoose !== 0 && this.typeCustomerChoose !== 1) {
      this.$router.push({name: 'choose-type-user', query: {id: this.$route.query.id}})
    }
    if (this.$route.query.store) {
      this.store_id = parseInt(this.$route.query.store)
    }
    if (this.$route.query.tabActive) {
      this.tabActive = parseInt(this.$route.query.tabActive)
    }
    if (this.$route.query.category) {
      this.category = parseInt(this.$route.query.category)
    }
    await this.getData();
  },
  methods: {
    ...mapActions([GET_SERVICE_LIST, SET_CHOOSE_PROCESS, TYPE_CUSTOMER, SET_STORE_ID, REMOVE_CHOOSE_PROCESS, SET_ALL_CHOOSE_PROCESS]),

    async getData() {
      this.startLoading();
      try {
        const {data} = await this.getListService();
        this.stores = data;
        this.generateData(data);
        this.endLoading();
      } catch(error) {
        this.endLoading();
        this.notifyError(error.message)
      }
    },

    generateData(data,flag=false){
      this.comboList = [];
      this.serviceList = []
      data.forEach(element => {
        if (element.id === this.store_id) {
          if (flag){
            element.service_store.forEach((ele) => {
              // if (ele.category_id == this.category){
                this.serviceList.push(ele)
              // }
            })
            element.combo_store.forEach((ele) => {
              if (ele.category_id == this.category) {
                this.comboList.push(ele)
              }
            })
          }else {
            element.service_store.forEach((ele) => {
              this.serviceList.push(ele)
            })
            element.combo_store.forEach((ele) => {
              this.comboList.push(ele)
            })
          }
        }
      })
    },
    formatNumber (number) {
      return new Intl.NumberFormat('ja-JP', { maximumSignificantDigits: 3 }).format(number)
    },
    closeModalCheckChoose() {
      this.dialogError = false;
    },
    chooseByCustomer(data, type){
        this.dialogChooseServiceType = true;
        this.currentChoose = data
        this.currentChoose.type = type
    },
    cancelChose (data, type) {
      let dataProcess = JSON.parse(JSON.stringify(this.dataProcess))
      dataProcess.forEach((item, index)=>{
        if(item.id === data.id && item.type === type){
          dataProcess.splice(index,1);
        }
      });
      this.setAllChooseProcess(dataProcess)
    },
    nextStepCheck() {
      if(this.dataProcess.length <= 0) {
        this.dialogError = true;
      } else {
        if(this.typeCustomerChoose === constants.USER_TYPE.NEW_CUSTOMER) {
          this.$router.push({ name: 'calendar-for-new-customer', params: this.dataProcess, query: {id: this.$route.query.id} });
        } else if(this.typeCustomerChoose === constants.USER_TYPE.REPEATER) {
          this.$router.push({ name: 'calendar-for-repeater', params: this.dataProcess, query: {id: this.$route.query.id} });
        }
      }
    },
    chooseTypeByCustomer (item, type) {
      this.currentChoose.type = type
      let dataProcess = JSON.parse(JSON.stringify(this.dataProcess))
      let check = dataProcess.filter(process => {
        return process.id === this.currentChoose.id && process.type === type
      })
      let checkProcess = dataProcess.filter(process => {
        return process.id === this.currentChoose.id && process.type === type && process.processItem.id === item.id
      })
      this.currentChoose.processItem = item
      if (check.length <= 0) {
        this.setChooseProcess(this.currentChoose);
      } else {
        this.cancelChose(this.currentChoose, type)
        if (checkProcess.length <= 0) {
          this.setChooseProcess(this.currentChoose);
        }
      }
      this.dialogChooseServiceType = false;
    },
    changeStore(){
      const store = this.stores;
      this.$store.dispatch(SET_STORE_ID, this.store_id)
      this.removeChooseProcess()
      this.generateData(store)
    },
    checkArrId(obj) {
      let check = false
      this.dataProcess.forEach(process => {
        if (process.id === obj.id && process.type === obj.type) {
          check = true
        }
      })
      return check
    },
    checkItemId(obj, type) {
      let check = false
      this.dataProcess.forEach(process => {
        if (process.type === type && process.processItem.id === obj.id) {
          if (obj.service_id && process.id === obj.service_id) {
            check = true
          }
          if (obj.combo_id && process.id === obj.combo_id) {
            check = true
          }
        }
      })
      return check
    },
    changeCategory(categoryId ,flag = false){
      const store = this.stores;
      if (categoryId == this.category || flag) {
        this.category = 0;
        this.generateData(store)
      }else {
        this.category = categoryId;
        this.generateData(store,true)
      }

    },
    activeTab(tab){
      this.tabActive = tab
      this.changeCategory(0,true)
    }
  }
};
</script>

<style scoped>
.el-form-item {
  margin-bottom: 5px;
}
button.el-button.btn-combo-choose.el-button--default.color-change {
  background-color: #fff;
}
form.el-form.custom-el-form-combo-list {
  padding-top: 5px;
  margin-top: 179px;
  margin-left: 120px;
  z-index: 1;
  position: relative;
}
button.el-button.btn-combo-detail.el-button--default {
  background-color: #ffc063;
  color: #fff;
  width: 40%;
}
label.el-form-item__label {
  font-size: 20px;
  font-weight: bold;
}
.el-select-combo-list {
  width: 100%;
}
button.el-button.btn-combo-list.el-button--default {
  width: 49%;
  float: right;
}
button.el-button.btn-combo-list-2.el-button--default {
  width: 49%;
  float: left;
  background-color: #85ce61;
}
.custom-btn-combo-detail.el-row {
  margin-bottom: 40px;
}
button.el-button.btn-combo-choose.el-button--de fault {
  width: 47%  !important;
}
label.el-form-item__label {
  text-align: left;
}
.el-button--success, .el-button--success:hover {
  color: #FFF;
  background-color: #85ce61;
  border-color: #85ce61;
  width: 40% !important;
}
.el-button--warning:focus, .el-button--warning:hover {
  background: #FFF !important;
  border-color: #85ce61 !important;
  color: #85ce61 !important;
  width: 47% !important;
}
.grid-content.bg-purple.custom-image-tag {
  width: 70%;
  margin-left: 50px;
}
.name-service.el-row {
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 25px;
  text-align: left;
}
.grid-content.bg-purple-light.custom-description {
  text-align: left;
}
.text-title{
  color: #191970;
  font-size: 20px;
  font-weight: 600;
}
.el-button--warning, .el-button--warning:hover {
  color: #FFF;
  background-color: #fff;
  border-color: #85ce61;
  color: #85ce61;
  width: 40% !important;
}
@media only screen and (max-width: 1000px) and (min-width: 350px){
  form.el-form.custom-el-form-combo-list {
    padding-top: 5px;
    margin-left: 0px;
  }
  .el-row-custom {
    display: flex;
    flex-direction: column;
  }
  .grid-content.bg-purple-light.custom-description {
    text-align: left;
    white-space: nowrap;
  }
  .custom-btn-combo-detail.el-row {
    margin-top: 0px;
    margin-bottom: 10px;
    display: block;
  }
}

  /*tung*/
  .el-tab-list-service {
    background: #f5f5f5;
  }
  .el-row-custom {
    padding: 23px;
  }
  .custom-btn-combo-detail{
    margin-bottom: 22px;
  }
.btn-submit-step{
  position: fixed;
  width: 100%;
  right: 0;
  bottom: 0;
  left: 50%;
  padding: 0;
  margin: 0;
  transform: translateX(-50%);
  background: #f5f5f5;
}
.active-search{
  background: #191970 !important;
}
.not-active-search{
  background: rgb(219, 219, 219);
}
.category_1{
  background:#435969 !important;
  color: white;
}
.category_2{
  background:#ff8c00 !important;
  color: white;
}
.category_3{
  background:#97C7CA !important;
  color: white;
}
.detail-content {
  content: #B2B2B2;
  font-size: 18px;
  font-weight: 600;
}
.category_default {
  background: rgb(219, 219, 219) !important;
  color: #191970;
}
.btn-category_default{
  border: 2px solid red;
}
.border-none-and-background-aqua-new{
  background-color: #191970;
  color: white;
}
.text-title-choose {
  font-size: 20px;
  font-weight: 600;
}
.service-combo-proceess th, td {
  border: none;
  text-align: left;
}
/* datnd */
@media only screen and (min-width: 992px) {
  .btn-submit-step button {
    width: 100%;
    max-width: 600px;
    padding: 12px 20px;
  }
}
</style>
