<template>
  <div>
    <process :step="2"></process>
        <h3 class="text-center custom-title-h3">お客様の情報を入力してください</h3>
      <div class="enter-user-body">
        <el-form label-width="140px" v-model="customerProfile" ref="customerProfile" class="el-form--label-left">
          <el-form-item label="氏名" prop="name_kanji" class="start-required text-bold">
            <el-input
                maxLength="100"
                @input.native="(e)=>{lengthInput(e, 100)}"
                placeholder="入力してください"
                v-model="customerProfile.name_kanji">
            </el-input>
          </el-form-item>
          <el-form-item prop="name_kana" label="フリガナ" class="start-required text-bold pt-3">
            <el-input
                maxLength="100"
                @input.native="(e)=>{lengthInput(e, 100)}"
                placeholder="入力してください"
                v-model="customerProfile.name_kana">
            </el-input>
          </el-form-item>
          <el-form-item prop="gender" label="性別" class="start-required text-bold enter-user-radio">
            <el-radio-group v-model="customerProfile.gender">
              <el-radio :label="1">男性</el-radio>
              <el-radio :label="2">女性</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item prop="birthday" label="生年月日" class="start-required text-bold">
            <el-date-picker class="customer-date-picker"
                type="date"
                :clearable="false"
                placeholder="入力してください"
                style="width: 100%;"
                format="yyyy/MM/dd"
                value-format="yyyy-MM-dd"
                @input="checkFullWithBirth"
                onkeydown="javascript: return event.keyCode === 8
                      ||event.keyCode === 46 ? true : !isNaN(Number(event.key))"
                v-model="customerProfile.birthday">

            </el-date-picker>
          </el-form-item>
          <el-form-item prop="phone" label="電話番号" class="start-required text-bold">
            <el-input
                maxLength="20"
                @input.native="(e)=>{lengthInput(e, 20)}"
                @blur="customerProfile.phone = customerProfile.phone.trim()"
                @input="checkFullWithPhone"
                onkeydown="javascript: return event.keyCode === 8
                      ||event.keyCode === 46 ? true : !isNaN(Number(event.key))"
                v-model="customerProfile.phone"
                placeholder="入力してください">
            </el-input>
          </el-form-item>
          <el-form-item prop="email" label="メールアドレス" class="start-required text-bold">
            <el-input placeholder="入力してください" maxLength="200" @blur="customerProfile.email = customerProfile.email.trim()" v-model="customerProfile.email" @input.native="(e)=>{lengthInput(e, 200)}"></el-input>
          </el-form-item>
          <el-form-item prop="address" label="住所" class="start-required text-bold pt-3">
            <el-input placeholder="入力してください" maxLength="500" @input.native="(e)=>{lengthInput(e, 500)}" @blur="customerProfile.address = customerProfile.address.trim()" v-model="customerProfile.address"></el-input>
          </el-form-item>
<!--          <el-form-item prop="store" label="よく通う店舗" class="text-bold w&#45;&#45;100">
            <el-select v-model="customerProfile.store_id" class="select-custom w&#45;&#45;100">
              <el-option
                  v-for="item in store"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="contract" label="契約書" class="text-bold">
            <template v-if="imageUrl">
              <router-link v-if="checkDevice" class="preview-contract" :to="{name: 'pdf', query: {path: imageUrl}}"><u>契約書</u></router-link>
              <a class="preview-contract" v-else :href="imageUrl">契約書</a>
            </template>
          </el-form-item>-->
<!--          <el-form-item prop="points" label="ポイント残高" class="text-bold enter-user-point">-->
<!--            <el-input disabled v-model="customerProfile.points"></el-input>-->
<!--            <span class="pt">pt</span>-->
<!--          </el-form-item>-->
          <el-form-item label="リマインダー送信先" class="text-bold type-line-email enter-user-checkbox">
            <el-checkbox v-model="checkBoxItemLine" label="Line"></el-checkbox>
            <el-checkbox v-model="checkBoxItemEmail" label="メールアドレス"></el-checkbox>
          </el-form-item>
          <p class="description">【注】顧客との相談により、上記の内容を更新させて頂く場合があります。詳細は<span><router-link :to="{name: 'privacy'}">プライバシーポリシー</router-link></span>、<span><router-link style="padding-left: 0px" :to="{name: 'term'}">利用規約</router-link></span>をご参考ください。</p>
          <div class="d-flex justify-content-center btn-enter-user">
            <Button customStyle="width:80%" @submit="editProfile">ご予約に進む<span style="padding-left: 10px"></span></Button>
          </div>
        </el-form>
      </div>
  </div>
</template>

<script>

import ApiService from "@/core/services/api.service";
import moment from 'moment';
import process from "@/components/layout/components/process";
import Button from "@/components/layout/components/ButtonCommon";
import {mapGetters} from "vuex";

export default {
  name: "profile",
  components : {
    process,
    Button
  },
  computed: {
    ...mapGetters(["currentUser", "dateBooking"]),
  },

  data() {
    return {
      customerProfile: {
        name_kana: '',
        name_kanji: '',
        gender: '1',
        birthday: '',
        phone: '',
        email: '',
        address: '',
        store_id: 1,
        imageUrl: '',
        points: '',
        notify_line: '',
        notify_email: '',
        contract : '',
      },
      imageUrl: '',
      store: {},
      checkBoxItemLine: false,
      checkBoxItemEmail: false,
      photo: null
    }
  },

  watch: {
    customerProfile(val) {
      if(val) {
        this.checkBoxItemLine = val.notify_line ? true : false
        this.checkBoxItemEmail = val.notify_email ? true : false
        if (!val.notify_line && !val.notify_email) {
          val.notify_line = true
          this.checkBoxItemLine = true
        }
      }
    },
    'checkBoxItemLine' () {
        if (!this.checkBoxItemLine && !this.checkBoxItemEmail) {
            this.checkBoxItemLine = true
        }
    },
    'checkBoxItemEmail' () {
      if (!this.checkBoxItemEmail && !this.checkBoxItemLine) {
          this.checkBoxItemLine = true
      }
    }
  },

  async created() {
    if (!Array.isArray(this.dateBooking) || this.dateBooking.length === 0) {
      this.$router.push({name: 'combo-list', query: {id: this.$route.query.id}})
    }
    this.customerProfile.store_id = this.$store.state.booking.store_id
    await this.getData();
  },

  methods: {
    formatDate(date) {
      return date ? moment(date).format('yyyy-MM-DD') : ''
    },
    checkFullWithBirth(e) {
      this.customerProfile.birthday = e.replace(/[！-～]/g, r => String.fromCharCode(r.charCodeAt(0) - 0xFEE0));
    },
    checkFullWithPhone(e) {
      this.customerProfile.phone = e.replace(/[！-～]/g, r => String.fromCharCode(r.charCodeAt(0) - 0xFEE0));
    },
    async getData() {
      this.startLoading();
      await ApiService.get(`profile/show`)
          .then(({data}) => {
            this.endLoading();
            this.customerProfile = data.data.user;
            if (this.customerProfile.notify_line && this.customerProfile.notify_email) {
              this.customerProfile.notify_line = true
              this.checkBoxItemLine = true
            }
            this.contract = data.data.user.contract
            this.imageUrl = data.data.user.contract ? data.data.user.contract : ''
            this.store = data.data.store;
            this.checkTypeLineOrEmail();
          })
          .catch((e) => {
            this.endLoading();
            if (e.response.status !== 401) {
              this.notifyError(e.response.message)
            }
          });
    },
    checkTypeLineOrEmail() {
      if (
          (this.customerProfile.notify_line === 0 && this.customerProfile.notify_email === 0)
          || (this.customerProfile.notify_line === 1 && this.customerProfile.notify_email === 0)) {
        this.checkBoxItemLine = true;
        this.checkBoxItemEmail = false;
      } else if (this.customerProfile.notify_line == 0 && this.customerProfile.notify_email == 1) {
        this.checkBoxItemEmail = true;
        this.checkBoxItemLine = false;
      } else if (this.customerProfile.notify_line == 1 && this.customerProfile.notify_email == 1) {
        this.checkBoxItemLine = true;
        this.checkBoxItemEmail = true;
      }
    },

    editProfile() {
      this.customerProfile.birthday = this.customerProfile.birthday ? moment(this.customerProfile.birthday).format('yyyy-MM-DD') : '';
      const convertFullToHalf = str => str.replace(/[！-～]/g, r => String.fromCharCode(r.charCodeAt(0) - 0xFEE0));
      this.customerProfile.birthday = typeof this.customerProfile.birthday == 'string' ? convertFullToHalf(this.customerProfile.birthday) : this.customerProfile.birthday;
      this.customerProfile.phone = typeof this.customerProfile.phone == 'string' ? convertFullToHalf(this.customerProfile.phone) : this.customerProfile.phone;
      this.customerProfile.notify_email = this.checkBoxItemEmail ? 1 : 0
      this.customerProfile.notify_line = this.checkBoxItemLine ? 1 : 0
      this.customerProfile.store_id = 1
      let customerProfile = JSON.parse(JSON.stringify(this.customerProfile))
      delete customerProfile.contract;
      const id = this.customerProfile.id;
      this.$refs.customerProfile.clearValidate(this.customerProfile)
      ApiService.post(`profile/update/` + id, customerProfile)
          .then(response => {
            if (response.data.code === 200) {
              this.$router.push({ name: 'confirm-booking', query: {id: this.$route.query.id}})
            } else if (response.code === 422) {
              this.showErrorMessage(response.data.data, "customerProfile");
            } else {
              this.notifyError('Error')
            }
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.showErrorMessage(error.response.data.data, "customerProfile");
            } else if (error.response.status === 500) {
              this.notifyError(error.response.statusText)
            } else {
              if (error.response.status !== 401) {
                this.notifyError(error.response.message)
              }
            }
          });
    },
  }
};
</script>

<style scoped>
.text-bold{
  font-weight: 600;
}
.custom-title-h3{
  color: #191970;
  font-weight: 700;
  padding: 30px 0px;
}

.pt {
  position: absolute;
  right: 10px;
  font-size: 14px;
  color: #606266;
  font-weight: 600;
}
.description {
  font-weight: 600;
  color: #8F8D89;
  font-size: 11px;
}
.btn-enter-user > button , .btn-enter-user > button > span > i{
  font-weight: 600;
  font-size: 20px;
}
.preview-contract {
  color: #66B0D1;
  font-weight: bold;
  font-size: 17px;
}
</style>
