<template>
  <div>
    <div class="choose-type-content">
      <div
        class="mb-4 d-flex justify-content-center btn-choose-customer"
        v-for="button in buttons"
        :key="button.content"
      >
        <Button
          @submit="navigate(button.route)"
          :content="button.content"
          :customStyle="button.style"
          :customClassName="button.className"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/layout/components/ButtonCommon";
export default {
  name: "my-page",
  components:{
    Button
  },
  data() {
    return {
      buttons: [
        {
          content: "プロフィール",
          route: "profile",
          style: "width:75%",
          className: "border-none-and-background-orange",
        },
        {
          content: "予約履歴",
          route: "history-booking",
          style: "width:75%",
          className: "border-none-and-background-aqua",
        },
      ],
    }
  },
  methods: {
    navigate(routeName) {
      this.$router.push({ name: routeName });
    }
  }

};
</script>

<style scoped>
.choose-type-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 70vh;
}
.btn-combo-choose > div > a {
  color: #191970 !important;
  font-size: 23px;
  font-weight: 600;
}
.btn-combo-choose > div > a > span {
  color: #191970 !important;
  font-size: 40px;
  font-weight: 100;
}
.btn-combo-choose >div > a:hover {
  text-decoration: none;
}
</style>
