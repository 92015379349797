<template>
  <div class="">
    <div>
      <process :step="3"></process>
    </div>
    <table class="confirm-booking-body">
      <tr class="tr_flex">
        <td class="text-title">サービス利用場所</td>
        <td>{{ store_name }}</td>
      </tr>
      <tr class="tr_flex">
        <td class="text-title">目安施術時間</td>
        <td>{{ bookingData.use_time }} <span class="time_use">分</span></td>
      </tr>
      <tr class="tr_flex">
        <div class="fl-bw">
          <td class="text-title">ご希望時間</td>
          <div class="time-booking">
            <td v-for="(item,index) in bookingData.array_time" :key="index">
              <p>{{ formatDate(item.date) + "&nbsp" + item.start_time }}</p>
            </td>
          </div>
        </div>
      </tr>
      <tr class="tr_flex tr_service">
        <td>
          <span class="text-title">サービス</span>
        </td>
        <td class="service_confirm">
          <div class="service-flex" v-for="(item,index) in dataProcess" :key="index">
            <div class="name-service">{{ item.name }}</div>
            <div>
              <p class="price-service"> {{ item.processItem.price }} <span>円</span></p>
            </div>
          </div>
        </td>
      </tr>
      <br>
      <tr class="tr_flex" prop="note" style="border:none">
        <!-- <td class="text-title">メモ</td> -->
        <el-input
            class="textarea_confirm_booking"
            maxLength="500"
            @input.native="(e)=>{lengthInput(e, 500)}"
            v-model="bookingData.note"
            type="textarea"
            :rows="10"
            placeholder="ご予約備考欄
例：
①第一希望予約を12:00としていますが、12:00-17:00の間いつでもok(17時には退出希望)
②当日、酵素ドリンク（ジンジャー）１本購入したい　　　　等">
        </el-input>
      </tr>
      <br>
      <tr class="tr_flex" style="border: none;">
        <td class="route_link">
          <router-link style="color:#191970;" to="term"><u>利用規約</u></router-link>
        </td>
        <td class="route_link">
          <router-link style="color:#191970;" to="privacy"><u>プライバシーポリシー</u></router-link>
        </td>
      </tr>
    </table>
    <div class="d-flex justify-content-center btn-enter-user">
      <Button customStyle="width:69%" class="btn-next-step" @submit="confirmBooking">上記に同意して予約を確定する</Button>
    </div>
    <!-- dialog error not choose -->
    <el-dialog :visible.sync="dialogError" width="30%" center>
      <div>
        <div class="modal-body content_popup" >
          <div class="custom-col el-form-custom" ref="dialogError">
            必須項目に入力してください。
          </div>
        </div>
        <div class="modal-footer text-center">
          <b-button style="width: 120px;" class="btn-back" @click="closeModalCheckChoose()">
            閉じる
          </b-button>
        </div>
      </div>
    </el-dialog>
    <Dialog :dialog="dialogVisible" @closeDialog="dialogVisible=false" @submit="routeErrorRequest ? $router.push({name: routeErrorRequest, query: {id: $route.query.id}}) : reload()"
            :close-on-click-modal="false">
      <div slot="content" class="text-center">
        <div class="parent-notification">
          <div class="check-container">
            <!-- <div class="check-background error">
              <img src="../../../../public/img/error.png"/>
            </div>
            <div class="check-shadow error-shadow"></div> -->
          </div>
          <div class="content">{{messageErrorRequest}}</div>
        </div>
      </div>
    </Dialog>
    <!-- end dialog error not choose -->
  </div>
</template>

<style>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>

<script>

import {mapGetters, mapActions} from "vuex";
import {SET_CHOOSE_PROCESS, DATE_BOOKING, TYPE_CUSTOMER, REMOVE_CHOOSE_PROCESS} from "@/core/services/store/booking.module";
import ApiService from "@/core/services/api.service";
// import StepNext from "@/components/stepBooking/stepBookingLayout/StepNext";
import * as constants from "@/core/config/constant";
import moment from 'moment';
import process from "@/components/layout/components/process";
import Button from "@/components/layout/components/ButtonCommon";
import Dialog from "@/components/layout/components/Dialog";

export default {
  name: 'confirm-booking',
  components : {
    // StepNext,
    Button,
    Dialog,
    process
  },
  data() {
    return {
      bookingData: {
        store_id: '',
        use_time: '',
        type: this.$store.state.booking.typeCustomer,
        status: 1,
        note: '',
        combo: [],
        service: [],
        array_time: []
      },
      store_name: '',
      dialogVisible: false,
      messageErrorRequest: '',
      routeErrorRequest: 'combo-list',
      dialogError: false,
    }
  },

  computed: {
    ...mapGetters(["dataProcess", "dateBooking", "currentUser", "typeCustomerChoose"]),
  },

  created() {
    if (!Array.isArray(this.dateBooking) || this.dateBooking.length === 0) {
      this.$router.push({name: 'combo-list', query: {id: this.$route.query.id}})
    }
    this.getData();
  },

  methods: {
    ...mapActions([SET_CHOOSE_PROCESS, DATE_BOOKING, TYPE_CUSTOMER, REMOVE_CHOOSE_PROCESS]),
    formatDate(date) {
      return date ? moment(date).format('yyyy年MM月DD日') : ''
    },
    reload() {
      window.location.reload()
    },
    getData() {
      this.bookingData.store_id = 1
      this.store_name = '浅草店';
      // if (this.bookingData.store_id === 1) {
      //   this.store_name = '白井店';
      // } else if (this.bookingData.store_id === 2) {
      //   this.store_name = '浅草店';
      // }
      this.dataProcess.forEach((val) => {
        this.data = val.processItem;
        if (this.data.service_id) {
          this.bookingData.service.push({
            service_id: this.data.service_id,
            service_process_id: this.data.id,
          })
        } else if (this.data.combo_id) {
          this.bookingData.combo.push({
            combo_id: this.data.combo_id,
            combo_process_id: this.data.id
          })
        }
        this.bookingData.use_time = parseInt(this.bookingData.use_time + this.data.use_time);
      });
      if(this.$store.state.booking.typeCustomer === constants.USER_TYPE.REPEATER) {
        this.bookingData.array_time = this.$store.state.booking.date.filter(time => {
          return time.date && time.start_time
        })
      } else if(this.$store.state.booking.typeCustomer === constants.USER_TYPE.NEW_CUSTOMER) {
        this.bookingData.array_time = [{
          date: this.$store.state.booking.date[0].date,
          start_time: this.$store.state.booking.date[0].time
        }];
      }

    },

    confirmBooking() {
      let url = 'booking'
      if (this.$route.query.id) {
        url = 'booking/' + this.$route.query.id
      }
      this.startLoading()
      ApiService.post(url, this.bookingData)
          .then(response => {
            this.endLoading()
            if (response.data.data.code === 200) {
              this.removeChooseProcess();
              this.dateBook('');
              this.typeCustomer('');
              this.$router.push({name: 'end-booking'})
            } else {
              this.notifyError(response.data.data.message)
            }
          })
          .catch(error => {
            this.endLoading()
            if (error.response.status === 422 && error.response.data.data && Array.isArray(Object.keys(error.response.data.data)) && Object.keys(error.response.data.data).length > 0) {
              let errorKey = error.response.data.data
              Object.keys(errorKey).every(key => {
                console.log(key)
                this.dialogVisible = true
                if (key.includes("service") || key.includes("combo")) {
                  this.messageErrorRequest = 'サービス・コース管理 : ' + errorKey[key][0]
                  this.routeErrorRequest = 'combo-list'
                  return
                }
                if (key.includes("array_time")) {
                  this.messageErrorRequest = 'ご希望時間 : ' + errorKey[key][0]
                  if(this.typeCustomerChoose === constants.USER_TYPE.NEW_CUSTOMER) {
                    this.routeErrorRequest = 'calendar-for-new-customer'
                  } else if(this.typeCustomerChoose === constants.USER_TYPE.REPEATER) {
                    this.routeErrorRequest = 'calendar-for-repeater'
                  }
                  return
                }
                if (key.includes("store_id")) {
                  this.messageErrorRequest = '店 : ' + errorKey[key][0]
                  this.routeErrorRequest = 'combo-list'
                  return
                }
                if (key.includes("type")) {
                  this.messageErrorRequest = 'タイプユーザー : ' + errorKey[key][0]
                  this.routeErrorRequest = 'choose-type-user'
                  return
                }
                this.messageErrorRequest = key + ' : ' + errorKey[key][0]
                this.routeErrorRequest = ''
              })
            } else {
              if (error.response.status !== 401) {
                this.notifyError(error.response.message)
              }
            }
          });
    },
  },
}
</script>

<style scoped>
.confirm-booking-body{
  padding: 5% 11% 2% 11%;
  border-collapse: unset;
  display: flex;
  flex-direction: column;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  text-align: left;
  padding: 8px;
}
table.table-confirm-booking {
  margin-left: 350px;
  width: 50%;
  margin-top: 210px;
}
.text-title{
  color: #191970;
  font-size: 25px;
}
.text-title ~ td,
.time-booking{
  color: #191970;
  font-weight: bold;
  font-size: 25px;
}
.time-booking {
  display: flex;
  flex-direction: column;
}
.tr_flex{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.tr_flex p {
  margin-bottom: 0;
}
.service_confirm{
  width: 100%;
}
.service-flex{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.name-service{
  font-size: 25px;
  color: #191970;
  font-weight: normal;
  width: 50%;
}
.price-service span{
  font-weight: normal;
}
.price-service{
  font-size: 25px;
  color: #191970;
  font-weight: bold;
}
.tr_service>td:first-child{
  margin-top: 29px;
}
.btn-next-step {
  margin:  95px 0 86px 0;
  font-size: 26px;
}
.textarea_confirm_booking{
  margin: 0 8px;
  font-size: 25px;
  border: 1px solid #AAA498;
  border-radius: 10px;
}
.textarea_confirm_booking textarea{
  border-radius: 10px;
  border: 1px solid #AAA498;
}
.time_use{
  font-weight: normal;
}
.route_link{
  font-size: 25px;
}
.fl-bw{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 900px) and (min-width: 350px){
  table.table-confirm-booking {
      margin-left: 0px;
      width: 100%;
    }
}
@media only screen and (max-width: 600px){
  .text-title,
  .text-title ~ td,
  .name-service,
  .price-service,
  .textarea_confirm_booking,
  .route_link,
  .time-booking{
    font-size: 14px;
  }
  .btn-next-step{
    font-size: 15px !important;
  }
}
.parent-notification {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>
